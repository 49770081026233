import React from 'react';
import type { FormInstance } from 'antd';
import { Modal, message } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import type { BaseData } from '../../utils';
import { request } from '../../utils';

export default class PriceTemplateModel {
  constructor() {
    this.getPageList();
    this.getCategoryFirst();
    this.getCityList();
    this.getPriceCategory();
  }

  @observable public modalOpen = false;

  @observable public firsList = [];

  @observable public secondList = [];

  @observable public cityList = [];

  @observable public unitOptions = [];

  public editId: string;

  @action
  public getPriceCategory = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType: 'price_category' },
    });
    this.unitOptions = req.data;
  };

  /**
   * 获取城市
   */
  @action
  public getCityList = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys_area/province/city/list',
    });
    this.cityList = req?.data || [];
  };

  /**
   * 获取一级类别
   */
  @action
  public getCategoryFirst = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/biz/category/list/type',
      params: { categoryType: 0 },
    });
    this.firsList = this.toOptions(req?.data);
  };

  /**
   * 根据一级类别获取二级类别
   * @param value
   */
  @action
  public getCategorySecond = async (value: string) => {
    const req = await request<{ data: any[] }>({
      method: 'POST',
      url: '/quan/biz/category/parent',
      data: { ids: `${value}` },
    });
    this.secondList = this.toOptions(req?.data);
  };

  @action
  public setModalOpen = () => {
    this.modalOpen = !this.modalOpen;
    if (!this.modalOpen) {
      this.formRef?.current?.resetFields();
      this.editId = undefined;
    }
  };

  @action
  public onDelete = (id) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该模版？',
      onOk: async () => {
        await request({
          method: 'POST',
          url: '/quan/biz/price/template/delete',
          data: { ids: `${id}` },
        });
        message.success('删除成功');
        runInAction(() => {
          this.current = 1;
          this.getPageList();
        });
      },
    });
  };

  @action
  public onEdit = async (id) => {
    const req = await request<{ data: any }>({
      method: 'GET',
      url: '/quan/biz/price/template/detail',
      params: { id },
    });
    this.editId = id;
    this.getSecondary(req.data.parentCategoryId);
    const detailList = {};
    req.data.detailList.forEach((item, i) => {
      detailList[i] = item;
    });
    console.log(detailList);
    this.formRef.current.setFieldsValue({
      ...req.data,
      regionId: [req?.data?.parentRegionId, req?.data?.regionId],
      detailList,
    });
    this.setModalOpen();
  };

  @action
  public onSubmit = () => {
    this.formRef?.current?.validateFields().then(async (data) => {
      const params = { ...data };
      if (params.regionId) {
        const [parentRegionId, regionId] = params.regionId;
        params.parentRegionId = parentRegionId;
        params.regionId = regionId;
      }
      if (params?.detailList) {
        params.detailList = Object.values(params?.detailList).filter((item: any) => item.duration || item.price || item.title || item.priceCategory);
      }
      const req = await request<{ msg: string }>({
        method: 'POST',
        url: this.editId ? '/quan/biz/price/template/edit' : '/quan/biz/price/template/add',
        data: {
          ...params,
          id: this.editId,
          showType: this.editId ? undefined : 1,
        },
      });
      message.success(req?.msg);
      this.getPageList();
      this.setModalOpen();
    });
  };

  @action
  public getSecondary = (value) => {
    this.formRef?.current?.setFieldValue('categoryId', undefined);
    this.getCategorySecond(value);
  };

  @observable public current = 1;

  @observable public pageSize = 50;

  @observable public total = 0;

  @observable public pageData = [];

  @action
  public onShowSizeChange = (current, size) => {
    this.current = current;
    this.pageSize = size;
    this.getPageList();
  };

  @action
  public getPageList = async () => {
    const req = await request<BaseData<{ list: any[]; total: number }>>({
      method: 'POST',
      url: '/quan/biz/price/template/list/page',
      data: {
        pageNum: this.current,
        pageSize: this.pageSize,
        showType: 1,
      },
    });
    this.total = req?.data?.total || 0;
    this.pageData = req?.data?.list || [];
  };

  public getPriceCategoryName = (id): string => {
    let name = '';
    this.unitOptions.forEach((item) => {
      if (item.dictValue === id) {
        name = item.dictName;
      }
    });
    return name;
  };

  public formRef = React.createRef<FormInstance>();

  public toOptions = (data: any[]): Array<{ label: string; value: string }> =>
    (data || [])?.map((item: any) => ({
      label: item.categoryName,
      value: item.id,
    }));
}
